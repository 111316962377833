/* eslint-disable @next/next/no-img-element */
"use client";

// import Image from "next/image"
// import { cn } from "@/lib/utils"
// import React from "react"
// import { IoBook } from "react-icons/io5"
// import { Book } from "@/graphql/generated/types"
// import Link from "next/link"

// type BookCoverProps = {
//   book?: {
//     primaryImageId?: Book["primaryImageId"]
//     title?: Book["title"]
//     slug?: Book["slug"]
//   }
//   hideFallback?: boolean
//   fallBackSize?: number
//   className?: string
//   style?: React.CSSProperties
// }

// const BookCover: React.FC<BookCoverProps> = (props) => {
//   const { book, hideFallback, fallBackSize, className, style, ...rest } = props

//   const imageWrapperClass = cn("relative aspect-[2/3] w-full", className)

//   const imageUrl =
//     book?.primaryImageId &&
//     `https://images.kaguya.io/books/${book.primaryImageId}-250w.webp`

//   return (
//     <Link href={book?.slug ? `/books/${book?.slug}` : ""}>
//       <div className={imageWrapperClass} style={{ ...style }}>
//         {imageUrl ? (
//           <Image
//             className={cn("object-cover object-center", imageWrapperClass)}
//             src={imageUrl}
//             alt={book.title || "Book cover"}
//             fill
//             {...rest}
//           />
//         ) : (
//           <BookCoverFallback
//             hideFallback={hideFallback}
//             fallBackSize={fallBackSize}
//           />
//         )}
//       </div>
//     </Link>
//   )
// }

// export default BookCover

// type BookCoverFallbackProps = {
//   fallBackSize?: number
//   hideFallback?: boolean
// }

// const BookCoverFallback = ({
//   fallBackSize,
//   hideFallback,
// }: BookCoverFallbackProps) => {
//   if (hideFallback) {
//     return null
//   }

//   return (
//     <div className="absolute inset-0 flex items-center justify-center bg-white/[6%] rounded-[6px]">
//       <IoBook size={fallBackSize || 44} className="fill-[#e6e6fa]/50" />
//     </div>
//   )
// }
import { cn } from "@/lib/utils";
import React from "react";
import { IoBook } from "react-icons/io5";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useResponsive } from "@/hooks/useResponsive";
export type BookCoverVariant = "thumbnail" | "card" | "large" | "huge";
type BookCoverProps = {
  book?: {
    images?: Book["images"];
    title?: Book["title"];
    slug?: Book["slug"];
  };
  hideFallback?: boolean;
  fallBackSize?: number;
  mobileFallbackSize?: number;
  className?: string;
  style?: React.CSSProperties;
  openInDialog?: boolean;
  useDivInsteadOfLink?: boolean;
  variant?: BookCoverVariant;
  fallbackClassName?: string;
  loading?: "eager" | "lazy";
};
const BookCover: React.FC<BookCoverProps> = props => {
  const {
    book,
    hideFallback,
    fallBackSize,
    className,
    mobileFallbackSize,
    style,
    openInDialog = false,
    useDivInsteadOfLink = false,
    variant = "card",
    fallbackClassName,
    loading,
    ...rest
  } = props;
  const {
    isMobile
  } = useResponsive();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imgRef = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    // If the image is already cached & complete,
    // then set loaded = true so we skip the skeleton/fade.
    if (imgRef.current && imgRef.current.complete) {
      setIsImageLoaded(true);
    }
  }, []);
  if (!book) {
    return null;
  }

  // const imageUrl = book?.images?.medium

  const Container = useDivInsteadOfLink ? "div" : Link;
  const href = book?.slug ? `/books/${book.slug}` : "";
  const small = book?.images?.small;
  const medium = book?.images?.medium;
  const large = book?.images?.large;
  const xl = book?.images?.xl;
  const hasImage = small && medium && large && xl;
  const imageWrapperClass = cn("relative aspect-[2/3] w-full", className);
  const fallbackSize = isMobile ? mobileFallbackSize ? mobileFallbackSize : 36 : fallBackSize ? fallBackSize : 80;
  if (!hasImage) {
    return <Container href={!useDivInsteadOfLink ? href : ""} onClick={handleClick}>
        <div className={imageWrapperClass} style={style}>
          <BookCoverFallback className={fallbackClassName} isMobile={isMobile} hideFallback={hideFallback} fallBackSize={fallbackSize} />
        </div>
      </Container>;
  }
  let sizesAttr = "";
  let srcSet = "";
  srcSet = hasImage ? `
    ${small} 128w,
    ${medium} 256w,
    ${large} 512w,
    ${xl} 1024w
  ` : "";
  const respVariant = isMobile ? "thumbnail" : variant;
  switch (respVariant) {
    case "thumbnail":
      // e.g. 80px container, maybe up to 100px
      sizesAttr = "(max-width: 500px) 80px, 120px";
      break;
    case "card":
      // e.g. 180px container typical, max maybe 240
      sizesAttr = "(max-width: 768px) 160px, 240px";
      break;
    case "large":
      // e.g. 340px container on smaller screens, 512 on bigger
      sizesAttr = "(max-width: 1024px) 340px, 512px";
      break;
    case "huge":
      // e.g. 600px container on smaller, up to 1024 for big screens
      sizesAttr = "(max-width: 1440px) 600px, 1024px";
      break;
    default:
      sizesAttr = "100vw";
  }
  function handleClick(e: React.MouseEvent) {
    if (openInDialog) {
      e.preventDefault();
      setDialogOpen(true);
    }
  }
  return <>
      <Container href={!useDivInsteadOfLink ? href : ""} onClick={handleClick} data-sentry-element="Container" data-sentry-source-file="BookCover.tsx">
        <div className={imageWrapperClass} style={style}>
          {!isImageLoaded && <div className={cn("absolute inset-0 flex items-center justify-center bg-gray-800/40", fallbackClassName)}>
              {/* Could be any skeleton, icon, spinner, etc. */}
              <IoBook size={fallbackSize} className="animate-pulse fill-slate-300/70" />
            </div>}
          <img src={small ?? ""} ref={imgRef} srcSet={srcSet} sizes={sizesAttr} loading={loading ?? "lazy"} onLoad={() => setIsImageLoaded(true)} className={cn("object-cover object-center", imageWrapperClass)} style={{
          opacity: isImageLoaded ? 1 : 0,
          transition: "opacity 0.3s ease-in-out"
        }}
        // src={imageUrl}
        alt={book?.title || "Book cover"}
        // fill
        {...rest} />
        </div>
      </Container>

      {openInDialog && <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent dialogCloseClassName="max-sm:-right-6 max-sm:-top-8 sm:hidden" className="max-sm:max-w-[300px] sm:max-w-fit bg-transparent items-center justify-center border-none p-0 transition-all duration-700">
            <div className="m-0 max-w-fit p-0 bg-transparent">
              {hasImage && <div className="flex max-w-fit items-center justify-center">
                  <img src={large} srcSet={srcSet} sizes={sizesAttr} alt={book?.title || "Book cover"} width={isMobile ? 300 : 400} height={isMobile ? 450 : 600} className={cn("object-cover border-none object-center rounded", isMobile && "w-[300px] h-[450px]")} />
                </div>}
            </div>
          </DialogContent>
        </Dialog>}
    </>;
};
export default BookCover;
type BookCoverFallbackProps = {
  fallBackSize?: number;
  hideFallback?: boolean;
  isMobile?: boolean;
  mobileFallbackSize?: number;
  className?: string;
};
const BookCoverFallback: React.FC<BookCoverFallbackProps> = ({
  fallBackSize,
  hideFallback,
  isMobile,
  className,
  mobileFallbackSize
}) => {
  if (hideFallback) {
    return null;
  }
  return <div className={cn("absolute inset-0 flex items-center justify-center bg-[#272834]", className)} data-sentry-component="BookCoverFallback" data-sentry-source-file="BookCover.tsx">
      <IoBook size={isMobile ? mobileFallbackSize ? mobileFallbackSize : 36 : fallBackSize ? fallBackSize : 80} className="fill-[#e6e6fa]/50" data-sentry-element="IoBook" data-sentry-source-file="BookCover.tsx" />
    </div>;
};