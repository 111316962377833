import { cn } from "@/lib/utils";
import React from "react";
const ButtonLoader = ({
  className
}: {
  className?: string;
}) => {
  return <div className={cn("flex flex-row gap-1.5")} data-sentry-component="ButtonLoader" data-sentry-source-file="ButtonLoader.tsx">
      <div className={cn("w-1 h-3 rounded-full bg-white animate-bounce", className)}></div>
      <div className={cn("w-1 h-3 rounded-full bg-white animate-bounce [animation-delay:-.2s]", className)}></div>
      <div className={cn("w-1 h-3 rounded-full bg-white animate-bounce [animation-delay:-.4s]", className)}></div>
    </div>;
};
export default ButtonLoader;